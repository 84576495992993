<template>
  <div style="flex-direction: column" class="vx-card">
    <div  class="flex m-2 md:flex-col w-full">
      <vs-col class="mr-3 mb-bas" >
        <vs-card style="height: 330px" class="mt-5 p-2 flex flex-col ">
          <div class="flex justify-content-between items-center">
            <div>
              <h3  style="color:#3C2352;">آخرین تیکت ها </h3>
            </div>
            <div v-if="ticketList.length > 0">
              <router-link to="/apps/ticket">
                <feather-icon style="font-size: 50px" icon="ChevronLeftIcon" />
              </router-link>
            </div>
          </div>
          <br />
          <br />
          <div v-if="ticketList.length > 0" class=" mt-5 ">
            <b-table
              thead-class="bg-gray-200"
              tbody-tr-class="ir-yekan-bold"
              tfoot-tr-class=""
              thead-tr-class="bg-"
              class="text-center rounded text-sm align-middle"
              responsive="lg"
              striped hover
              :fields="fields"
              :items="ticketList">
              <template #cell(subject)="sub">
                <div @click="selectTicket(sub.item.id)" class="w-full cursor-pointer">
                  <p class="truncate secondary-text">{{ sub.value }}</p>
                </div>
              </template>
              <template #cell(category_id)="id">
                <div @click="selectTicket(id.item.id)" class="w-full cursor-pointer">
                  <p class="truncate">{{ departementText(id.value) }}</p>
                </div>
              </template>
              <template #cell(agent_fullname)="id">
                <div @click="selectTicket(id.item.id)" class="w-full cursor-pointer">
                  <p class="truncate">{{ id.item.agent_fullname }}</p>
                </div>
              </template>
              <template #cell(status_id)="row">
                <span class="cursor-pointer" @click="selectTicket(row.item.id)" :style="{ color: statusColor(row.value) }">{{
                    statusText(row.value)
                  }}</span>
              </template>
            </b-table>
          </div>
          <div class="flex flex-column justify-center items-center w-full" v-else>
            <img style="opacity: 40" width="80" src="@/assets/images/elements/speech-bubble.png" alt="haio-logo">
            <h5 class="mt-5">تیکتی وجود ندارد !    </h5>
          </div>
        </vs-card>
      </vs-col>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotificationCard',
  data () {
    return {
      Loading: false,
      notificationList: [],
      ticketList: [],
      fields: [
        { key: 'id', label: 'شماره ' },
        { key: 'subject', label: 'عنوان تیکت    ' },
        { key: 'category_id', label: 'دپارتمان ' },
        { key: 'agent_fullname', label: '  پاسخ دهنده ' },
        { key: 'status_id', label: '  وضعیت ' }
      ],
      items: []
    }
  },
  methods : {
    selectTicket (id) {
      // console.log($e)
      this.$router.push(`/apps/tickets/${id}`)
    },
    departementText (id) {
      const arr = ['پشتیبانی فنی', ' فروش و بازاریابی', 'مالی و اداری']
      return arr[+id - 1]
    },
    statusColor (id) {
      const arr = ['#0070C0', 'FFC000', ' #92D050', '#00B050', '#FFC000', '#00B0F0', '#333']
      return arr[+id - 1]
    },
    statusText (id) {
      const arr = ['جدید', 'در انتظار پاسخ', 'پاسخ مشتری', 'پاسخ کارشناس', 'در حال بررسی', 'در دست اقدام', 'بسته شده']
      return arr[+id - 1]
    },
    getTiket () {
      this.$http.get('/ticket').then(res => {
        this.ticketList = res.data.params.data.reverse().slice(0, 3)
      }).catch(err => {
        this.$vs.notify({
          color: '',
          Text: err.response.data.message,
          title: 'تیکت'
        })
      })
    },
    getUserNotification () {
      this.Loading = true
      this.$http.get('NotificationsListForNotifBox').then(res => {
        this.notificationList = res.data.params
        this.Loading = false
      }).catch(err => {
        console.log(err.response.data.message)
      })
    }
  },
  mounted () {
    this.getUserNotification()
    this.getTiket()
  }
}
</script>

<style scoped>
  .blue-light {
    background-color: #BFDBFE !important;
    border-radius: 1rem;
    filter: drop-shadow(5px 5px 10px #BFDBFE);
  }
  .bg-card {
    box-shadow: none !important;
    background-color: #e9ebec !important;
  }
  .hover-item:hover {
    transition: 0.5s;
    background-color: #E5E7EB !important;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
  }
  .badgeAreaWaitAnswer {
    background-color: #fa9276;
    padding: 2px;
    color: white;
    border-radius: 2rem;
    font-size: 8px !important;
  }
  .badgeAreaAnswered {
    background-color: #6EE7B7;
    padding: 2px;
    color: white;
    border-radius: 2rem;
    font-size: 8px !important;
  }
</style>
