<template>
  <vs-card style="flex-direction: column-reverse" class="mt-5 mr-2 p-1 lg:w-4/12 md:w-12/12 sm:w-12/12 sm:flex-col bg-box rounded-lg shadow-sm">
    <div class=" mt-2 flex items-center ">
      <div class=" flex justify-center white-light p-3">
        <b-icon style="font-size: 15px" icon="bar-chart-line-fill" />
      </div>
      <h4 class=" mt-2 ml-4 iranYekan-black"> سرویس های فعال</h4>
    </div>
    <vs-divider />
    <div style="font-family: dana !important;" class="flex dana-font justify-center mt-8 ">
      <PieChart style="font-family: dana !important;" :key="ms[0]" @callNow="changeState" class="dana-font" :width="350" :height="250" />
    </div>
  </vs-card>
</template>

<script>
import PieChart from './PieChart.vue'
export default {
  name: 'ProductCard',
  components: {PieChart},
  data () {
    return {
      options: {
        legend: {
          labels: {
            FontSize: 980,
            defaultFontColor: '#fd0000',
            FontFamily: 'dana'
          }
        }
      }
    }
  },
  computed : {
    ms () {
      return this.$store.state.DataChart
    }
  },
  methods : {
    changeState () {
      this.showChart = true
    }
  },
  mounted () {
    // this.showChart = true
  },
  created () {
    // this.showChart = true
  }
}
</script>

<style scoped>
.bg-box {
  background-color: #e9f1fa !important;
}
.white-light {
  background-color: #ffffff !important;
  border-radius: 1rem;
  filter: drop-shadow(5px 5px 10px #98befb);
}
</style>
