<template>
  <div>
    <vx-card>
      <h5 class=" mb-base">
        در این بخش میتوانید آی پی ها موجود در جزیره را به این vm وصل و قطع
        نمایید.
      </h5>
      <div class="flex" v-if="data.public_ip">
        <p class="mx-2 ">آی پی پابلیک VM :</p>
        <p class="text-info" v-if="data.public_ip">{{ data.public_ip }}</p>
      </div>
      <div v-else>
        <p class="text-danger">این vm دارای آی پی پابلیک نمی باشد.</p>
      </div>
      <div v-if="data.public_ip">
        <b-button @click="detachIp" class="mt-4 mx-3 " variant="danger">
          قطع اتصال
        </b-button>
      </div>
      <div v-else>
        <label>آی پی : </label>
        <br/>
        <b-form-select
          class="w-25 mx-4 px-4 mt-4"
          v-model="selectedIp"
          :options="options"
        ></b-form-select>
        <b-button @click="attachIp" class="mt-4 " variant="success">
          اتصال
        </b-button>
      </div>
    </vx-card>
  </div>
</template>
<script>
export default {
  emits: ['refresh'],
  props: {
    data: { type: Object }
  },
  data () {
    return {
      vmId: '',
      vpcId: '',
      options: [],
      selectedIp: '',
      publicIpId: ''
    }
  },
  methods: {
    attachIp () {
      this.$http
        .post('/vira/cloud/publicip/set', {
          public_ip_id: this.selectedIp.public_ip_id,
          vm_id: this.vmId
        })
        .then(res => {
          this.data.public_ip = this.selectedIp.ip_address
          this.$vs.notify({
            text: res.data.message,
            color: 'success'
          })
        })
        .catch(err => {
          this.$vs.notify({
            text: err.response.data.message,
            color: 'success'
          })
        }).finally(() => {
          this.$emit('refresh')
        })
    },
    detachIp () {
      this.$http
        .post('/vira/cloud/publicip/set', {
          public_ip_id: this.data.public_ip_id || this.selectedIp.public_ip_id
        })
        .then(res => {
          this.data.public_ip = null
          this.$vs.notify({
            text: res.data.message,
            color: 'success'
          })
        })
        .catch(err => {
          this.$vs.notify({
            text: err.response.data.message,
            color: 'danger'
          })
        })
        .finally(() => {
          this.getAvailableIp()
          this.$emit('refresh')
        })
    },
    getAvailableIp () {
      this.$http
        .get(`/vira/cloud/publicip/list?vpc_id=${this.vpcId}`)
        .then(result => {
          const select = []
          result.data.params.data.forEach(el => {
            if (!el.vm_id) {
              select.push({ value: el, text: el.ip_address })
            } else {
              this.publicIpId = el.public_ip_id
            }
          })

          this.options = select
        })
        .catch(err => {
          console.log(err)
        })
    }
  },
  async created () {
    this.vmId = this.data.id

    this.vpcId = this.data.vpc_id
    // this.publicIpId = this.data.public_ip_id

    this.getAvailableIp()
  }
}
</script>
