<template>
  <div style="flex-direction: column" class="flex w-full lg:flex-row sm:flex-col">
    <div class="flex m-2 md:flex-col w-full">
      <vs-col class="mr-3 mb-bas" >
        <vs-card style="height: 330px" class="mt-5 p-2 flex flex-col ">
          <div class="flex justify-content-between items-center">
            <div>
              <h3  >     آخرین اعلان ها </h3>
            </div>
            <div v-if="notificationList.length > 0">
              <router-link to="/notification/list">
                <feather-icon style="font-size: 50px" icon="ChevronLeftIcon" />
              </router-link>
            </div>
          </div>
          <div class="mt-5">
            <br />
            <br />
            <div v-if="notificationList.length > 0" dir="rtl">
              <table v class="table rounded table-striped table-hover table-bordered">
                <tbody class="rounded">
                <tr v-for="(item, index) in notificationList" :key="index">
                  <router-link class="text-decoration-none" :to="{ path: `/notification/show/${item.id}`, query: { type: 0 } }">
                    <td class="p-1 haio-hover flex items-center justify-content-between">
                        <div class="mt-2">
                          <p style="font-size: 12px">{{ item.title }}    </p>
                        </div>
                        <div>
                          <img width="17" src="../../../assets/images/solid/left-arrow.png" />
                        </div>
                    </td>
                  </router-link>
                </tr>
                </tbody>
              </table>
            </div>
              <div class="flex flex-column justify-center items-center  w-full" v-else>
                <img style="opacity: 40" width="70" src="@/assets/images/elements/bell (2).png" alt="haio-logo">
                <h5 class="mt-5">اعلانی وجود ندارد !    </h5>
              </div>
          </div>
        </vs-card>
      </vs-col>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuestionAnswer',
  data () {
    return {
      ticketList: [],
      notificationList: [],
      FakeData : [
        {id: 1, title: 'امن‌سازی ssh سرورهای لینوکسی', url: 'https://www.haio.ir/securing-ssh-linux-servers/'},
        {id: 2, title: 'تغییرات قیمت در سرور های ابری فرانسه', url: 'https://www.haio.ir/price-changes-in-french-cloud-servers/'},
        {id: 3, title: 'ریبوت و خاموش‌ کردن اجباری در لینوکس', url: 'https://haio.ir/%D8%B1%DB%8C%D8%A8%D9%88%D8%AA-%D8%A7%D8%AC%D8%A8%D8%A7%D8%B1%DB%8C-%D9%84%DB%8C%D9%86%D9%88%DA%A9%D8%B3/'},
        {id: 4, title: 'معرفی انواع سرور ابری', url: 'https://www.haio.ir/introduction-of-cloud-server-types/'}
      ]
    }
  },
  methods: {
    getUserNotification () {
      this.Loading = true
      this.$http.get('NotificationsListForNotifBox').then(res => {
        this.notificationList = res.data.params
        this.Loading = false
      }).catch(err => {
        console.log(err.response.data.message)
      })
    },
    getTiket () {
      this.$http.get('/ticket').then(res => {

        this.ticketList = res.data.params.data.reverse().slice(0, 4)

      }).catch(err => {
        this.$vs.notify({
          color: '',
          Text: err.response.data.message,
          title: 'تیکت'
        })
      })
    }
  },
  mounted () {
    this.getUserNotification()
  }
}
</script>
