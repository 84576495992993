<template>
  <vs-card v-if="false" style="flex-direction: column-reverse" class="mt-5 mr-2 p-1 lg:w-4/12 md:w-12/12 sm:w-12/12 sm:flex-col rounded-lg shadow-sm">
    <div class="flex flex-col">
      <div class="w-12/12  flex justify-content-between items-center">
        <h3 class="mt-4 " style="color:#3C2352;">  هاست های فعال</h3>
        <div v-if="hostList.length > 0">
          <!-- <vs-tooltip text=" ساخت  هاست جدید  ">
            <router-link to="/apps/create/host">
              <vs-button size="small" radius icon="add" color="primary" class="circle-btn-haio"></vs-button>
            </router-link>
          </vs-tooltip> -->
        </div>
      </div>
      <div style="margin-top: 50px !important;" v-if="hostList.length > 0 " class=" mt-5 ">
        <div v-for="(host, index) in hostList" :key="index">
          <div  class="flex cursor-pointer justify-content-between">
            <h5
              @click="goToHostDetails(host.id)"
              class="cursor-pointer"
              >{{host.domain_name}}</h5>
            <div style="color: lightslategray" v-if="loading">
              در حال ورود به پنل کاربری
              ....
            </div>
            <div
              class="cursor-pointer"
              @click="goToPanel(host.id)"
            >
              <img class="primary-text" width="20" src="../../../assets/images/svg/enter.svg" alt="">
            </div>
          </div>
          <div style="color: lightslategray;" class="flrx justify-content-end">
            <vs-divider />
            تاریخ تمدید:
             {{ host.next_invoice }}
          </div>
          <hr style="border-top:1px dashed #000"/>
        </div>
      </div>
      <div class="m-6" v-else>
        <div class="flex flex-column justify-center items-center w-full">
          <img width="120" src="@/assets/images/elements/open-box.svg" alt="haio-logo">
          <div>
            <!-- <router-link to="/apps/create/host">
              <vs-button class="rounded" size="medium"  icon="add" color="primary">ساخت هاست جدید</vs-button>
            </router-link> -->
          </div>
        </div>
      </div>
    </div>
  </vs-card>
</template>

<script>
import FeatherIcon from '../../../components/FeatherIcon.vue'
export default {
  components: { FeatherIcon },
  name: 'PriceCard',
  data () {
    return {
      Consume: '',
      ConsumeArr: [],
      fields: [
        { key: 'host_titledomain_name', label: '' },
        { key: 'actions', label: '  عملیات ' }
      ],
      sumCal : '',
      verifyoffCode:'',
      offCode: '',
      encteCode: '',
      loading : false,
      userLevel:'',
      isOff: false,
      offPercent:'',
      hostList: [],
      offValueCalculate: '',
      render: false,
      Loading: false,
      amountCharge : 80000,
      activePrompt : false,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: .60
      }
    }
  },
  methods : {
    goToHostDetails (id) {
      this.$router.push(`/apps/list/host/${id}`)

    },
    goToPanel (id) {
      this.loading = true
      this.$http
        .post('/host/account/login/url', { host_id: id })
        .then((result) => {
          this.loading = false
          window.open(result.data.params.data, '_blank')
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // CallDigit () {
    //   this.Consume = JSON.stringify(this.$store.state.DataConsume)
    //   JSON.parse(this.Consume).map((item) => {
    //     this.ConsumeArr.push(item)
    //   })
    //   this.sumCal = 0
    //   // setTimeout(() => {
    //   //   this.$store.state.DataConsume[0].forEach((int) => {
    //   //     this.sumCal += Object.values(int)[0]
    //   //   })
    //   // }, 500)
    // },
    acceptAlert () {
      this.$vs.loading()
      this.$http.post('https://api.haio.ir/v1/invoice/charge/add', {
        amount: this.amountCharge,
        off_code: this.verifyoffCode,
        payment_side:'2',
        redirect_url: 'https://console.haio.ir/pages/payment'
      })
        .then(async (res) => {
          window.location = (res.data.params.payment_url)
          this.$vs.loading.close()
          // console.log("Set follow txt record at your dns server:")
          // console.log(res.data.params)
        })
        .catch(error => {
          this.$vs.loading.close()
          this.$vs.notify({
            color:'danger',
            title:' شارژ حساب',
            text:error.response.data.message
          })
        })
    },
    getHostList () {
      this.$http
        .get('/host/account/list')
        .then((result) => {
          this.hostList = result.data.params.data.reverse().slice(0, 2)
        })
        .catch((err) => console.log('err host', err))
    },
    confirmOffCode () {
      this.$vs.loading()
      this.$http.post(`/sale/cooperation/code/validation?off_code=${this.offCode}`).then((res) => {
        if (res.data.code === 200) {
          this.$vs.loading.close()
          this.offPercent = res.data.params.off
          this.verifyoffCode = this.offCode
          this.offValueCalculate = this.amountCharge * this.offPercent / 100
          this.$vs.notify({text: 'کد تخفیف اعمال شد ', color: '#24c1a0'})
        }
      }).catch((err) => {
        this.$vs.loading.close()
        this.$vs.notify({text: err.response.data.message})
      })
    }
  },
  computed: {
    // CART DROPDOWN
    realPrice () {
      return ((this.amountCharge * 1) + (0.10 * this.amountCharge))
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    },
    activeUser () {
      return this.$store.state.AppActiveUser
    }
  },
  mounted () {
    this.Loading = true
    this.getHostList()
    if (this.sumCal > 0) {
      this.Loading = false
      this.render = true
    }
  }
}
</script>

<style scoped>
.bg-box {
  background-color: #e9f1fa !important;
}
.white-light {
  background-color: #ffffff !important;
  border-radius: 1rem;
  filter: drop-shadow(5px 5px 10px #98befb);
}
</style>
