<template>
  <div>
    <!-- <div @click="showNotification(Message[0].id, 1)" style="flex-direction: column" v-if="showEssentilaMessage && Message.length > 0" class="w-full cursor-pointer flex md:flex-row xl:flex-row items-center bg-alert text-white justify-between rounded-lg p-5">
      <div  class="flex cursor-pointer items-center justify-between">
        <div class="bg-white dan rounded-circle w-10 h-10 text-center flex items-center justify-center p-2 mr-5">
          <b-icon style="color: #3f2553FF" class="" :icon="Message[0].icon" />
        </div>
        <span style="font-weight: bold" class="text-lg iranYekan-med ">{{ Message[0].title }}</span>
      </div>
      <div style="margin-top: 5px" v-if="Message[0].link_to !== null && ''" class="flex justify-start md:mt-2 sm:mt-2 ">
        <a :href="Message[0].link_to" target="_blank" style="color: #3f2553FF" class="bg-white p-2 rounded-pill dana-font ">مشاهده</a>
      </div>
      <div v-else>
        <vs-button style="color: #3f2553FF" class="bg-white p-2 rounded-pill dana-font " @click="showNotification(Message[0].id, 1)">مشاهده</vs-button>
      </div>
    </div> -->

   <div style="flex-direction: column"  v-for="(item, index) in MessageModeOne" :key="index" class="w-full flex md:flex-row xl:flex-row items-center bg-alert text-white justify-between mt-2 rounded-lg p-5">
     <div class="flex items-center justify-between">
       <div class="bg-white dan rounded-circle w-10 h-10 text-center flex items-center justify-center p-2 mr-5">
         <b-icon style="color: #3f2553FF" class="" :icon="item.icon" />
       </div>
       <span style="font-weight: bold" class="text-lg iranYekan-med ">{{ item.title }}</span>
     </div>
     <div style="margin-top: 5px" v-if="item.link_to !== null" class="flex justify-start md:mt-2 sm:mt-2 ">
       <a :href="item.link_to" target="_blank" style="color: #3f2553FF" class="bg-white p-2 rounded-pill dana-font ">مشاهده</a>
     </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'EssentialMessage',
  data () {
    return {
      Message: '',
      MessageModeOne: '',
      showEssentilaMessage: false
    }
  },
  methods : {
    getEssentialMessage () {
      this.$http.get('EssentialMessage').then(res => {
        if (res.data.params.length > 0) {
          this.Message = res.data.params
          this.showEssentilaMessage = true
        }
      }).catch(err => {
        console.log(err)
      })
    },

    showNotification (id, isEssentional = 0) {
      this.showDropdownNotification = false
      setTimeout(() => {
        this.showDropdownNotification = true
      }, 100)
      if (isEssentional === 0) {
        this.$router.push({path: `/notification/show/${id}`})
      }
      this.$router.push({path: `/notification/show/${id}`, query: {type: isEssentional}})
    },
    getEssentialMessageBroadCast () {
      this.$http.get('essentialMessageBroadCast').then(res => {
        this.MessageModeOne = res.data.params
      }).catch(err => {
        console.log(err)
      })
    }
  },
  mounted () {
    this.getEssentialMessage()
    this.getEssentialMessageBroadCast()
  }
}
</script>

<style scoped>
  .bg-alert {
    background: #20223c;
    /* box-shadow: #3f2553FF 0 5px 5px; */
    /* background-image: linear-gradient(to right, #b1d4f14d 33%, rgba(255,255,255,0) 0%); */
    background-position: bottom;
    background-size: 10px 1px;
    background-repeat: repeat-x;
  }
</style>
