

<template>
  <div @load.once="refreshPage()" id="dashboard-analytics">
    <!--   Essential message-->
    <div>

      <b-tooltip target="tooltip-target-1" triggers="hover">
        نرخ مصرف آی‌پی: {{ Intl.NumberFormat('fa-IR', { maximumSignificantDigits: 8 }).format(activeUserInfo.cloud_service_hourly_cost.cloud_public_ip_cost) }} تومان در ساعت<br>
        نرخ مصرف سخت‌افزار: {{ Intl.NumberFormat('fa-IR', { maximumSignificantDigits: 8 }).format(activeUserInfo.cloud_service_hourly_cost.cloud_vm_cost) }} تومان در ساعت
      </b-tooltip>

      <b-button id="tooltip-target-1"  class="  px-4 rounded-lg py-3 mr-3 bg-h-primary-light w-full md:w-1/4 hover:bg-h-primary-light border-0 border-b-4 border-solid ">نرخ مصرف حساب کاربری: {{ Intl.NumberFormat('fa-IR', { maximumSignificantDigits: 8 }).format(activeUserInfo.cloud_service_hourly_cost.cloud_sum_cost) }} تومان در ساعت</b-button>


      <br>
      <br>
      <vs-alert v-if="activeUserInfo.identity != 1" color="warning" class="p-2 rounded mb-4">
        احراز شماره تماس (جهت استفاده از خدمات و دریافت 10 هزار امتیاز) (
        <vs-button :to="{ name: 'page-user-settings', query: { activeTab: 1 } }" size="small" color="warning" style="padding: 5px">ورود به بخش احراز شماره تماس</vs-button>
        )
      </vs-alert>

      <vs-alert v-if="!activeUserInfo.email_verify" color="warning" class="p-2 rounded mb-4">
        احراز ایمیل (اختیاری: جهت دریافت کد ورود و ارسال مشخصات سرویس ها و دریافت 10 هزار امتیاز) (
        <vs-button :to="{ name: 'page-user-settings', query: { activeTab: 2 } }" size="small" color="warning" style="padding: 5px">ورود به بخش احراز ایمیل</vs-button>
        )
      </vs-alert>

      <br>
      اعلانات
      <EssentialMessage/>
    </div>

    <!--  end Essential message-->
    <!--StarterContainer area-->
    <div class="vx-card">
      <b-container class="bv-example-row mt-5 p-5">
        <b-row>
            <input type="text" class="form-control bg-h-primary-light border-grey border-dashed border-opacity-25 h-12 placeholder-white placeholder-opacity-25 w-50" placeholder="کد شارژ هدیه" v-model="couponCode" >
            <vue-button-spinner
              :is-loading="isLoading"
              :disabled="isLoading"
              class="cursor-pointer rounded   ml-2 mt-2 p-4  w-20 h-5 "
              >

              <span @click="submitCode()" >
                ثبت کد
              </span>

            </vue-button-spinner>

        </b-row>
      </b-container>

    </div>
    <div class="vx-card">
      <b-container class="bv-example-row mt-5 p-5">
        <b-row>
          <b-col class="bg-h-gray text-left">
            <h3 class="">IaaS (زیرساخت)</h3>

            <b-button   to="/instance/list" class="cursor-pointer rounded btn-success text-lg mb-2 p-4 text-white w-50">سرور ابری</b-button> <br>
<!--            <b-button   to="/instance/auction/list" class="cursor-pointer rounded btn-danger text-lg mb-2 p-4 text-white w-50">سرور ابری حراجی</b-button>  &nbsp;<a target="_blank" class="cursor-pointer rounded bg-white p-4" href="https://doc.haio.ir/cloud-auction-server/instance-create">راهنما</a>-->

          </b-col>
          <b-col class="bg-h-gray text-left">
            <h3 class="">PaaS</h3>
            <b-button   to="/docker/list" class="cursor-pointer rounded btn-info text-lg mb-2 p-4 text-white w-50">کلاستر Docker Swarm</b-button> <br>
            <b-button   to="/vdi/list" class="cursor-pointer rounded btn-danger text-lg mb-2 p-4 text-white w-50">دسکتاپ مجازی</b-button> <br>
            <b-button   to="/anti/sanction" class="cursor-pointer rounded btn-info text-lg mb-2 p-4 text-white w-50">ضد تحریم</b-button> <br>

          </b-col>
          <!-- <b-col class="bg-h-gray text-center">
            <h3 class="p-5">PaaS</h3>

            <vs-button   to="/instance/create" class="cursor-pointer rounded py-1 mb-2 bg-h-info text-white">سرور ابری</vs-button> <br>
            <vs-button   to="/instance/create" class="cursor-pointer rounded py-1 mb-2 bg-h-info text-white">سرور ابری حراجی</vs-button>

          </b-col> -->

        </b-row>
      </b-container>

    </div>

</div>
</template>

<script>
import VxCard from '../components/vx-card/VxCard.vue'
import NotificationCard from './components/Notification/NotificationCard.vue'
import QuestionAnswer from './components/Notification/QuestionAnswer.vue'
import StarterContainer from './components/StarterContainer/StarterContainer.vue'
import ProductCard from './components/ItemsCards/ProductCard.vue'
import PriceCard from './components/ItemsCards/PriceCard.vue'
import EssentialMessage from './components/Notification/EssentialMessage.vue'
import FastAccess from './components/ItemsCards/FastAccess.vue'
import VueButtonSpinner from 'vue-button-spinner'

export default {
  components: {
    FastAccess, EssentialMessage, ProductCard,  StarterContainer, NotificationCard, VxCard, PriceCard, QuestionAnswer, VueButtonSpinner

  },

  data () {
    return {
      fields: [
        {
          key: 'last_name',
          sortable: true
        },
        {
          key: 'first_name',
          sortable: false
        },
        {
          key: 'age',
          label: 'Person age',
          sortable: true,
          // Variant applies to the whole column, including the header and footer
          variant: 'info'
        }
      ],
      items: {},
      checkpointReward: {},
      subscribersGained: {},
      ordersRecevied: {},
      salesBarSession: {},
      supportTracker: {},
      productsOrder: {},
      salesRadar: {},
      userName: '',
      couponCode: '',
      isLoading: false,
      status: '',

      dispatchedOrders: []
    }
  },
  computed:{
    activeUserInfo () {
      return this.$store.state.AppActiveUser
    }
  },
  methods:{
    submitCode () {
      this.isLoading = true

      this.$http.post('/invoice/charge/code', {charge_code: this.couponCode}).then((result) => {
        this.isLoading = false
        this.status = false // or success
        this.$vs.notify({
          color: 'success',
          title: ' عملیات موفق',
          text: result.data.message
        })
        this.getinfo()
      }).catch((err) => {
        this.isLoading = false
        this.status = false // or success

        this.$vs.notify({
          text : err.response.data.message
        })
      })
    },
    contest () {
      this.$vs.loading({
        container: '#div-with-loading',
        scale: 0.6
      })
      this.$http.post('/contest/subscribe').then((result) => {
        this.$vs.loading.close('#div-with-loading > .con-vs-loading')
        this.$vs.notify({text: result.data.message})
      }).catch((err) => {
        this.$vs.notify({
          text : err.response.data.message
        })
      })
    },
    getinfo () {
      const header = localStorage.getItem('accessToken')
      this.$store.dispatch('auth/fetchUserData', header)
    },

    checkLogin () {

      if (localStorage.getItem('accessToken')) {
        // const payload = localStorage.getItem('accessToken')

      } else {

        // this.$router.push({path: '/pages/login'})
        // this.$vs.notify({
        //   title: 'Login Attempt',
        //   text: 'ابتدا وارد شوید!',
        //   iconPack: 'feather',
        //   icon: 'icon-alert-circle',
        //   color: 'primary'
        // })

      }


    },

    servicesUsage () {
      this.$http.get('/user/consume').then((result) => {
        // console.log(result)
        const data = result.data.params
        this.items = data
      }).catch((err) => {
        console.log(err)
      })
    }
    // async addInstance () {
    //   const insData = {
    //     vm_title: 'haio-competition',
    //     vm_hostname: 'haio-competition',
    //     template_id: '27',
    //     vm_vcpu: '2',
    //     vm_ram: '2',
    //     vm_hard: '35',
    //     template_version: 'latest'
    //   }
    //   // console.log(insData)
    //   this.$vs.loading()
    //   try {
    //     const res = await this.$http.post('/vira/cloud/vm/create/simple', insData)
    //     this.$vs.notify({
    //       color: 'success',
    //       title: res.data.message,
    //       text: ' وی ام شما برای مسابقه در حال ایجاد است اطلاعات به ایمیل شما ارسال میشود'
    //     })

    //   } catch (err) {

    //     this.$vs.notify({
    //       color: 'danger',
    //       title: ' خطا',
    //       text: err.response.data.message
    //     })
    //   }
    //   this.$vs.loading.close()
    //   this.$router.push('/apps/cloudlist/')
    // }

  },


  created () {

    this.checkLogin()
    setTimeout(this.getinfo, 2000)

  }

}
</script>

<style lang="scss">
/*! rtl:begin:ignore */
#dashboard-analytics {
  .greet-user{
    position: relative;

    .decore-left{
      position: absolute;
      left:0;
      top: 0;
    }
    .decore-right{
      position: absolute;
      right:0;
      top: 0;
    }
  }

  @media(max-width: 576px) {
    .decore-left, .decore-right{
      width: 140px;
    }
  }

.parent-wrapper {
  position: relative;
  width: 400px;
  height: 270px;
  margin: 40px auto 0;
  background-image: url('http://www.imgbase.info/images/safe-wallpapers/digital_art/1_miscellaneous_digital_art/41750_1_miscellaneous_digital_art_simple_dark_shapes.jpg');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position-y: -600%;
 background-color: #d387ab; background-image: linear-gradient(315deg, #d387ab 0%, #b279a7 74%);
  border-radius: 4px;
  color: #FFF;
  box-shadow: 0px 0px 50px 5px rgba(0, 0, 0, 0.5);
}

.close-btn {
  margin: 20px;
  font-size: 18px;
  cursor: pointer;
}

.subscribe-wrapper {
  position: absolute;
  left: -30px;
  right: -30px;
  height: 200px;
  padding: 30px;
  background-image: url('https://i.imgur.com/MRjF1PL.png?1');
  background-position-x: 272%;
  background-position-y: -1px;
  background-repeat: no-repeat;
  background-color: #FFF;
  border-radius: 4px;
  color: #333;
  box-shadow: 0px 0px 60px 5px rgba(0, 0, 0, 0.4);
}

.subscribe-wrapper:after {
  position: absolute;
  content: "";
  right: -10px;
  bottom: 71px;
  width: 0;
  height: 0;
  border-left: 0px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #7149c7;
}

.subscribe-wrapper h4 {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 28px;
}

.subscribe-wrapper input {
  position: absolute;
  bottom: 30px;
  border: none;
  border-bottom: 1px solid #d4d4d4;
  padding: 10px;
  width: 65%;
  background: transparent;
  transition: all .25s ease;
}

.subscribe-wrapper input:focus {
  outline: none;
  border-bottom: 1px solid #a77cf4;
}

.subscribe-wrapper .submit-btn {
  position: absolute;
  border-radius: 30px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  background-color: #a77cf4;
  color: #FFF;
  padding: 12px 25px;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  // letter-spacing: 2px;
  right: -10px;
  bottom: 30px;
  cursor: pointer;
  transition: all .25s ease;
  box-shadow: -5px 6px 20px 0px rgba(51, 51, 51, 0.4);
}

.subscribe-wrapper .submit-btn:hover {
  background-color: #8e62dc;
}
}
/*! rtl:end:ignore */
</style>
