<template>
  <div>
<!--
    <div style="flex-direction: column" class="w-full mt-8 bg-h-primary-light border-danger cursor-pointer flex md:flex-row xl:flex-row items-center bg-alert text-white justify-between rounded-lg p-5 border-dashed border-0 border-b">
       <div  class="flex cursor-pointer items-center justify-between">
        <div class="bg-primary dan rounded-circle w-5 md:w-10 h-5 md:h-10 text-center flex items-center justify-center p-2 mr-2 md:mr-5">
          <b-icon style="color: #3f2553FF" class="" />
        </div>
      </div> -->

        <!-- <a  href="https://www.haio.ir/vdi/" target="_blank" class="text-white">  <span style="font-weight: bold" class="tex-sm md:text-lg iranYekan-med "> معرفی سرویس جدید دسکتاپ مجازی</span></a>
      <div style="margin-top: 5px"  class="flex justify-start md:mt-2 sm:mt-2 ">
        <a  href="https://www.haio.ir/vdi/" target="_blank" style="color: #3f2553FF" class="bg-white p-2 rounded-pill dana-font ">مشاهده</a>
      </div>

     </div>
-->
  </div>
</template>

<script>
export default {
  name: 'StarterContainer',
  data () {
    return {
      isShow: true
    }
  },
  methods: {
    CloseStarterContainer () {
      this.isShow = false
      localStorage.setItem('CloseStarterContainer', false)
    },
    CanShow () {
      const isShowStatus = localStorage.getItem('CloseStarterContainer')
      if (isShowStatus === 'false') {
        this.isShow = false
      } else {
        this.isShow = true
      }
    }
  },
  computed: {
    windowWidth () {
      return this.$store.state.windowWidth
    }
  },
  created () {
    this.CanShow()
  }
}
</script>
