<script>
import { Pie } from 'vue-chartjs'
// import {mapActions} from 'vuex'
export default {
  extends: Pie,
  data () {
    return {
      dataLabel: [],
      dataLabelKey: [],
      dataValue: [],
      dataStore: []
    }
  },
  props: {
    labelData: Array
  },
  methods : {
    cartCong () {
      return (
        this.renderChart(
          {
            labels: this.$store.state.DataChart[0],
            datasets: [
              {
                backgroundColor: [this.gradient, this.gradient2, '#00D8FF'],
                data: JSON.parse(localStorage.getItem('dataValue'))
              }
            ]
          },
          { responsive: true, maintainAspectRatio: false }
        )
      )
    },
    getConsume () {
      this.$http.get('user/consume').then(res => {
        this.$store.dispatch('PushDataConsume', res.data.params)
        res.data.params.map((item) => {
          this.dataLabel = this.dataLabel.concat(Object.keys(item).toString())
          this.dataValue = this.dataValue.concat(Object.values(item))
        })
        this.$store.dispatch('PushDataChart', this.dataLabel)
        this.dataStore = JSON.stringify(this.$store.state.DataChart)
        this.dataLabelKey =  JSON.parse(this.dataStore)[0]
        localStorage.setItem('dataLabel', JSON.stringify(this.dataLabel))
        localStorage.setItem('dataValue', JSON.stringify(this.dataValue))
      }).catch(error => {
        console.log('err', error)
      })
    }
  },

  mounted () {
    // this.getConsume()
    this.gradient = this.$refs.canvas
      .getContext('2d')
      .createLinearGradient(0, 0, 0, 450)
    this.gradient2 = this.$refs.canvas
      .getContext('2d')
      .createLinearGradient(0, 0, 0, 450)

    this.gradient.addColorStop(0, 'rgba(255, 0,0, 0.5)')
    this.gradient.addColorStop(0.5, 'rgba(255, 0, 0, 0.25)')
    this.gradient.addColorStop(1, 'rgba(255, 0, 0, 0)')

    this.gradient2.addColorStop(0, 'rgba(0, 231, 255, 0.9)')
    this.gradient2.addColorStop(0.5, 'rgba(0, 231, 255, 0.25)')
    this.gradient2.addColorStop(1, 'rgba(0, 231, 255, 0)')
    // this.cartCong()
    this.$emit('callNow', true)
  }

}
</script>
